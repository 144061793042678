import React from "react";
import { GET_SCHOOLS } from "../../../../Qurries";
import { useQuery } from "@apollo/client";
import { PaymentSmallCard } from "../../Transaction/TransactionReceipt";
import { Link } from "react-router-dom";

function SchoolDetails({ schoolId }: { schoolId: String }) {
  const { data, loading, refetch } = useQuery(GET_SCHOOLS);
  const school = data?.getSchoolQuery?.schools?.filter((school: any) => {
    return school.school_id === schoolId;
  })[0];

  return (
    <div>
      <div className="overflow-hidden border rounded-md p-4 my-4">
        <div className="grid  lg:!grid-cols-4 grid-cols-2 gap-2">
          <div className=" col-span-1">
            <PaymentSmallCard
              title="Institute Name"
              value={school?.school_name}
            />
          </div>
          <div className=" col-span-1">
            <PaymentSmallCard title="Institute ID" value={school?.school_id} />
          </div>
          <div className=" col-span-1">
            <PaymentSmallCard
              title="Institute Email ID"
              value={<span className=" lowercase">{school?.email}</span>}
            />
          </div>
          <div className=" col-span-1">
            <PaymentSmallCard title="Phone No." value={school?.phone_number} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SchoolDetails;
