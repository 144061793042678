import { Select } from "edviron-ui-package";
import React, { useEffect, useState } from "react";
import { CREATE_MDR_REQUEST } from "../../../Qurries";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

export const preventNegativeValues = (e: any) =>
  ["+", "-"].includes(e.key) && e.preventDefault();
const preventPasteNegative = (e: any) => {
  const clipboardData = e.clipboardData || window.Clipboard;
  const pastedData = parseFloat(clipboardData.getData("text"));
  if (pastedData < 0) {
    e.preventDefault();
  }
};
export const isValidNumber = (input: string) => {
  const isEmptyOrWhitespace = /^\s*$/;
  return !isEmptyOrWhitespace.test(input) && !isNaN(Number(input));
};

export const RangeCard = ({
  onDelete,
  cardIndex,
  cards,
  disabled = false,
  breakPoint,
  fees,
  setFees,
  setBreakPoint,
  type,
  setType,
  amountType,
  setAmountType,
}: any) => {
  const error =
    (cardIndex === 0 &&
      cards.length > 1 &&
      (breakPoint[cardIndex] <= 0 || !isValidNumber(breakPoint[cardIndex]))) ||
    (cardIndex > 0 &&
      cardIndex < cards.length &&
      Number(breakPoint[cardIndex - 1]) >= Number(breakPoint[cardIndex]));
  const error1 =
    cardIndex < cards.length - 1 && isNaN(Number(breakPoint[cardIndex]));

  return (
    <div>
      <div className="flex justify-between items-center">
        <h3 className=" text-sm font-medium text-edvion_black">
          Charge {cardIndex + 1}
        </h3>
        <h3 className=" text-sm font-medium text-edvion_black">Amount</h3>
      </div>
      <div className="flex justify-between gap-4 items-center mt-4">
        <div className="flex items-end gap-4 min-w-[16rem] max-w-[20rem]">
          <input
            type="number"
            name="initialRange"
            disabled={true}
            value={cardIndex === 0 ? 0 : breakPoint[cardIndex - 1]}
            placeholder="set initial range"
            className="rounded-lg border text-[#000000CC] text-xs border-[#959595] px-4 py-1.5 w-full focus:outline-none placeholder:text-[#00000066] placeholder:text-xs "
          />

          <input
            type="text"
            name="finalRange"
            pattern="/^[0-9∞]*$/"
            onChange={(e) => {
              if (/^[0-9∞]*$/.test(e.target.value)) {
                setBreakPoint(
                  breakPoint.map((v: any, i: number) => {
                    if (i === cardIndex) {
                      return e.target.value;
                    } else {
                      return breakPoint[i];
                    }
                  })
                );
              }
            }}
            disabled={disabled || cardIndex === cards.length - 1}
            placeholder="set final range"
            value={cardIndex === cards.length - 1 ? "∞" : breakPoint[cardIndex]}
            className={
              "rounded-lg border text-[#000000CC] text-xs border-[#959595] px-4 py-1.5 w-full focus:outline-none placeholder:text-[#00000066] placeholder:text-xs " +
              ((error || error1) && " border border-red-500")
            }
          />
        </div>
        <div className="items-center flex gap-x-2">
          <div className="w-20">
            <input
              type="number"
              name="amount"
              value={fees[cardIndex]}
              onChange={(e) =>
                setFees(
                  fees.map((v: any, i: number) => {
                    if (i === cardIndex) {
                      return e.target.value;
                    } else {
                      return fees[i];
                    }
                  })
                )
              }
              disabled={disabled}
              placeholder="₹"
              className={
                "rounded-lg border text-[#000000CC] text-xs border-[#959595] px-4 py-1.5 w-full focus:outline-none placeholder:text-[#00000066] placeholder:text-xs placeholder:text-right "
              }
              min={0}
              onKeyDown={preventNegativeValues}
              onPaste={preventPasteNegative}
            />
          </div>

          <Select
            className="max-w-fit -mt-2"
            selected={
              type[cardIndex] === "" ? amountType : { name: type[cardIndex] }
            }
            setSelected={(e: any) => {
              setAmountType({
                name: e.name,
              });
              setType(
                type.map((v: any, i: number) => {
                  if (i === cardIndex) {
                    return e.name;
                  } else {
                    return type[i];
                  }
                })
              );
            }}
            options={["PERCENT", "FLAT"].map((value) => {
              return { name: value };
            })}
          />
        </div>
      </div>
      <div className="flex justify-end mt-2">
        {!disabled && cards.length > 1 && cards.length === cardIndex + 1 && (
          <button
            className="text-[#6F6AF8] text-xs font-medium"
            onClick={onDelete}
          >
            Delete
          </button>
        )}
      </div>
    </div>
  );
};

function BulkRangeCard({
  card_data = [1],
  breakPoint_data = [],
  fees_data = [0],
  amountType_data = ["PERCENT"],
  trusteeId,
  schoolIds,
  platform_type,
  payment_mode,
  setOpen,
}: any) {
  const [cards, setCards] = useState<any>([1]);
  const [breakPoint, setBreakPoint] = useState<any>([]);
  const [fees, setFees] = useState<any>([0]);
  const [isValidInput, setIsValidInput] = useState<boolean>(false);
  const [createMdr] = useMutation(CREATE_MDR_REQUEST);
  const [amountType, setAmountType] = useState({
    name: "PERCENT",
  });
  const [type, setType] = useState<any>([amountType.name]);

  useEffect(() => {
    setCards(card_data);
    setBreakPoint(breakPoint_data);
    setFees(fees_data);
    setType(amountType_data);
  }, []);

  useEffect(() => {
    setIsValidInput(
      !breakPoint
        .map((c: any, i: number) => {
          if (i === 0) {
            return isValidNumber(breakPoint[i]) && breakPoint[i] > 0;
          } else {
            return (
              isValidNumber(breakPoint[i - 1]) &&
              isValidNumber(breakPoint[i]) &&
              Number(breakPoint[i - 1]) < Number(breakPoint[i])
            );
          }
        })
        .includes(false)
    );
  }, [breakPoint, fees]);

  const addCard = () => {
    setCards([...cards, cards.length]);
    if (cards.length > 0) {
      setBreakPoint([...breakPoint, ""]);
    }
    if (cards.length > 0) setFees([...fees, 0]);
    if (cards.length > 0) setType([...type, ""]);
  };
  const deleteCard = (index: any) => {
    setCards(cards.filter((cardIndex: any, i: any) => i !== index));
    setBreakPoint(breakPoint.slice(0, breakPoint.length - 1));
    setFees(fees.slice(0, fees.length - 1));
    setType(type.slice(0, type.length - 1));
  };

  return (
    <div className="h-[300px] bg-white shadow rounded-md p-4 flex flex-col justify-between">
      <div>
        <div className="flex justify-between items-center mb-8">
          <button
            className="capitalize text-[#6F6AF8] text-xs font-medium"
            onClick={addCard}
          >
            + add new range
          </button>
        </div>
        <div className="space-y-4 overflow-hidden overflow-y-auto h-[180px] pr-6">
          {cards.map((cardIndex: any, index: any) => (
            <RangeCard
              breakPoint={breakPoint}
              setBreakPoint={setBreakPoint}
              amountType={amountType}
              setAmountType={setAmountType}
              type={type}
              setType={setType}
              key={index}
              cards={cards}
              fees={fees}
              setFees={setFees}
              cardIndex={index}
              onDelete={() => deleteCard(index)}
            />
          ))}
        </div>
      </div>
      <div className="flex justify-end">
        <button
          disabled={!schoolIds.length}
          onClick={async () => {
            breakPoint.push(null);

            let range_charge: any = [];
            fees.map((fee: any, index: number) => {
              let upto = breakPoint[index]
                ? parseInt(breakPoint[index], 10)
                : null;
              range_charge.push({
                upto: upto,
                charge: parseInt(fee, 10),
                charge_type: type[index] === "" ? amountType.name : type[index],
              });
            });

            try {
              const res = await createMdr({
                variables: {
                  school_id: schoolIds,
                  platform_charge: {
                    platform_type: payment_mode.label,
                    payment_mode: platform_type.label,
                    range_charge,
                  },
                },
              });
              if (res?.data?.createMdrRequest) {
                toast.success(res?.data?.createMdrRequest);
                setOpen(false);
              }
            } catch (err) {
              setOpen(false);
            }
          }}
          className="rounded-lg disabled:bg-[#D9D9D978] disabled:text-[#959595] bg-blue-700 text-white px-8 py-1 max-w-fit"
        >
          Set Charges
        </button>
      </div>
    </div>
  );
}

export default BulkRangeCard;
