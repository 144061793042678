import React, { useEffect } from "react";

function AdminLogin() {
  const urlSearchParms = new URLSearchParams(window.location.search);
  const jwtToken = urlSearchParms.get("token") || "";
  localStorage.removeItem("token");
  useEffect(() => {
    if (jwtToken) {
      localStorage.setItem("token", jwtToken);
    }
  }, [jwtToken]);
  if (jwtToken) return window.location.replace("/");
  return null;
}

export default AdminLogin;
