import React, { useState } from "react";
import { addFromValues } from "./PreviousRate";
const _ = require("lodash");
const SchoolPaymentMode = ({ mode }: any) => {
  const [rangeCharge, setRangeCharge] = useState<any>(mode?.range_charge);

  const preventNegativeValues = (e: any) =>
    ["+", "-"].includes(e.key) && e.preventDefault();

  const filterdArray = addFromValues(
    _.sortBy(rangeCharge, (item: any) =>
      item.upto === null ? Infinity : item.upto
    )
  );

  return filterdArray.map((range: any, rangeIndex: number) => {
    return (
      <div className="grid gap-x-2 grid-cols-5" key={rangeIndex}>
        <div>
          <p className=" text-[#1B163B] text-xs uppercase font-normal">
            {mode?.platform_type} (
            <span className="text-[8px]">{mode?.payment_mode}</span>)
          </p>
        </div>
        <div>
          <p className=" text-[#1B163B] text-xs font-normal">
            {range?.upto
              ? `${range?.from > 0 ? range?.from : "<="} ${
                  range?.upto >= range?.from && range?.from !== 0
                    ? "-" + range?.upto
                    : range?.upto
                }`
              : `>=${range?.from}`}
          </p>
        </div>
        <div>
          <p className=" text-[#1B163B] text-xs font-normal">
            {range?.charge_type === "FLAT"
              ? "₹" + range?.base_charge
              : range?.base_charge + "%"}
          </p>
        </div>
        <div>
          <p className=" text-[#1B163B] text-xs font-normal">
            {range?.charge_type === "FLAT"
              ? "₹" + range?.commission?.toFixed(2)
              : range?.commission?.toFixed(2) + "%"}
          </p>
        </div>
        <div>
          <p className=" text-[#1B163B] text-xs font-normal">
            {range?.charge_type === "FLAT"
              ? "₹" + range?.charge
              : range?.charge + "%"}
          </p>
        </div>
      </div>
    );
  });
};
function FinalRateTab({ data }: any) {
  return (
    <div className="px-6 py-8 bg-[#EDF1F4] space-y-6 h-full max-h-screen overflow-y-auto">
      {data === null ? (
        <p className=" text-xs text-[#1B163B]">
          The final rates will be displayed once the admin has taken action on
          the request.
        </p>
      ) : (
        <>
          <div className="grid grid-cols-5">
            <div>
              <p className=" text-[#777777] text-xs font-normal">
                Payment Mode
              </p>
            </div>
            <div>
              <p className=" text-[#777777] text-xs font-normal">Range</p>
            </div>
            <div>
              <p className=" text-[#777777] text-xs font-normal">Base Rates</p>
            </div>
            <div>
              <p className=" text-[#777777] text-xs font-normal">
                My Commission
              </p>
            </div>
            <div>
              <p className=" text-[#777777] text-xs font-normal">Final Rates</p>
            </div>
          </div>
          {data?.map((mode: any, i: any) => {
            return <SchoolPaymentMode mode={mode} key={i} />;
          })}
        </>
      )}
    </div>
  );
}

export default FinalRateTab;
