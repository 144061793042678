import React, { useState } from "react";
import LogoSvg from "./assets/svg/voice_control_ofo1.svg";
import EdvironLogo from "./assets/svg/logo.svg";
import { GET_USER, LOG_IN_TRUSTEE, RESET_MAILS } from "../../Qurries";
import { useMutation, useQuery } from "@apollo/client";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "edviron-ui-package";
import loadIcond from "./assets/svg/loading-purple.svg";

function LoginPage({ user, set_user }: any) {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [loadingIcon, setLoadingIcon] = useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [showLoadingModal, setShowLoadingModal] = React.useState(false);

  const [LOGIN_TO_TRUSTEE, { loading }] = useMutation(LOG_IN_TRUSTEE, {
    errorPolicy: "ignore",
  });

  const [resetMailsMutation] = useMutation(RESET_MAILS);

  // const [sendResetMailm, { data }] = useMutation(RESET_MAILS, {
  //   variables: { email }
  // })
  const { refetch } = useQuery(GET_USER);

  const navigate = useNavigate();

  const resetPass = async (email: any) => {
    try {
      setShowLoadingModal(true);
      const { data } = await resetMailsMutation({
        variables: { email },
      });

      if (data && data.resetMails && data.resetMails.active) {
        setShowLoadingModal(false);
        toast.success("Password reset email sent successfully.");
        setShowModal(!showModal);
      } else {
        setShowLoadingModal(false);
        toast.error("Error sending reset email. Please check your email.");
      }
    } catch (error: any) {
      setShowLoadingModal(false);
      console.error("Error sending reset email:", error.message);
    }
  };

  if (user) return <Navigate to="/"></Navigate>;
  if (loadingIcon) {
    return (
      <div className="w-full h-full flex">
        <img className="m-auto" src={loadIcond} alt="" />
      </div>
    );
  }

  return (
    <div className="w-full h-screen grid gap-x-8 lg:!grid-cols-5 grid-cols-1">
      <div className=" col-span-2 w-full flex flex-col lg:items-start items-center md:p-20 px-10 py-20 bg-[#eceefa] xl:rounded-r-[20px]">
        <div>
          <img src={EdvironLogo} className="w-50 h-full" alt="Edviron log" />
        </div>
        <div className="mt-20">
          <h3 className="font-bold text-2xl">Sign in to your account</h3>
        </div>

        <form
          onSubmit={async (e) => {
            e.preventDefault();
            const res = await LOGIN_TO_TRUSTEE({
              variables: {
                email,
                password,
              },
            });
            if (res.data !== null) {
              localStorage.setItem("token", res.data.loginTrustee.token);
              await refetch().then((data) => {
                if (data) set_user(data);
              });
              navigate("/");
            }
          }}
          className="md:max-w-[25rem] w-full space-y-4 mt-12"
        >
          <div className="flex flex-col gap-y-2">
            <label
              className="capitalize font-medium text-base"
              htmlFor="emailId"
            >
              Email
            </label>
            <div className="bg-white w-full border border-gray-200 rounded-lg overflow-hidden">
              <input
                type="email"
                className="w-full p-3 px-4 focus:outline-none"
                id="emailId"
                placeholder="email@gmail.com"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            <label
              className="capitalize font-medium text-base"
              htmlFor="password"
            >
              Password
            </label>
            <div className="bg-white flex items-center pr-3 w-full border border-gray-200 rounded-lg overflow-hidden">
              <input
                type={passwordVisible ? "text" : "password"}
                className="w-full p-3 px-4 focus:outline-none"
                id="password"
                placeholder="enter password"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              {passwordVisible ? (
                <i
                  onClick={() => setPasswordVisible(false)}
                  className="fa-solid fa-eye-slash cursor-pointer"
                ></i>
              ) : (
                <i
                  onClick={() => setPasswordVisible(true)}
                  className="fa-solid fa-eye cursor-pointer"
                ></i>
              )}
            </div>
          </div>
          <div>
            <button className="bg-[#d6daf3] w-full px-4 py-3 mt-4 rounded-lg">
              {loading ? (
                <i className="fa-solid animate-spin fa-spinner"></i>
              ) : (
                "Sign In"
              )}
            </button>
          </div>
        </form>
        <div className="md:max-w-[25rem] w-full mt-2">
          <button
            onClick={() => setShowModal(!showModal)}
            className="bg-[#d6daf3] w-full px-4 py-3 mt-4 rounded-lg"
          >
            Reset Password
          </button>
        </div>
      </div>
      <Modal
        className="max-w-lg w-full"
        open={showModal}
        setOpen={setShowModal}
        title="Reset Password"
      >
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            try {
              // Call resetPass function with the current email state
              await resetPass(resetEmail);
            } catch (error: any) {
              console.error("Error sending reset email:", error.message);
            }
          }}
        >
          <input
            type="email"
            name="Email"
            placeholder="Enter Email"
            className="w-full p-3 px-4 focus:outline-none"
            onChange={(e) => setResetEmail(e.target.value)}
            required
          />

          <div className="mt-2 mb-2 text-center">
            <button className="bg-[#d6daf3] w-full px-4 py-3 mt-4 rounded-lg">
              Send Email
            </button>
          </div>
        </form>
      </Modal>
      <Modal
        className="max-w-lg w-full"
        open={showLoadingModal}
        setOpen={setShowLoadingModal}
      >
        <div className="w-full h-full flex">
          <img className="m-auto" src={loadIcond} alt="" />
        </div>
      </Modal>
      <div className="col-span-3 w-full hidden lg:flex items-center px-32 justify-center">
        <img src={LogoSvg} className="w-full h-full" alt="" />
      </div>
    </div>
  );
}

export default LoginPage;
