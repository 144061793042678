import React, { useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { _Table, Pagination } from "../../../../components/Table";
import { useQuery } from "@apollo/client";
import { GET_ALL_VENDOR_SETTLEMENT } from "../../../../Qurries";
import { amountFormat } from "../../../../utils/amountFormat";
function VendorSettlement() {
  const [searchText, setSearchText] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const { data, loading } = useQuery(GET_ALL_VENDOR_SETTLEMENT, {
    variables: {
      page: currentPage,
      limit: 10,
    },
  });

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };

  return (
    <div>
      {loading ? (
        <AiOutlineLoading3Quarters className=" animate-spin text-xl" />
      ) : (
        <_Table
          perPage={true}
          exportBtn={true}
          heading={"Settlements"}
          copyContent={[9]}
          //filter={[searchText]}
          // searchBox={
          //   <div className="w-full ">
          //     <div className="flex xl:!flex-row flex-col justify-between gap-2  w-full xl:items-center items-start mb-2">
          //       <div className="bg-[#EEF1F6] py-3 items-center flex  px-6 xl:max-w-md max-w-[34rem] w-full rounded-lg">
          //         <i className="text-edvion_black text-opacity-50 fa-solid text-xs fa-magnifying-glass"></i>
          //         <input
          //           className="ml-4 text-xs bg-transparent focus:outline-none w-full placeholder:font-normal"
          //           onFocus={(e) =>
          //             (e.target.style.borderColor = "transparent")
          //           }
          //           type="text"
          //           placeholder=" Search(Name,Order ID...)"
          //           value={searchText}
          //           onChange={(e) => {
          //             setSearchText(e.target.value);
          //           }}
          //         />
          //       </div>
          //     </div>
          //   </div>
          // }
          data={[
            [
              "Sr.No",
              "Institute Name",
              "Vendor Name",
              "Settlement Amount",
              "Adjustment",
              "Payment Amount",
              "From",
              "Till",
              "Status",
              "UTR No",
              "Settlement Date",
            ],

            ...(data?.getAllVendorSettlementReport?.vendor_settlements
              .filter((d: any) => {
                const arr = [
                  d.school_id,
                  d.vendor_id,
                  d.trustee_id,
                  d.vendor_name,
                ].join(",");
                return arr.toLowerCase().includes(searchText.toLowerCase());
              })
              .map((settlement: any, index: number) => [
                <div className="truncate">{index + 1}</div>,
                <div className="truncate">{settlement?.school_name}</div>,
                <div className="truncate">{settlement?.vendor_name}</div>,
                <div className=" truncate">
                  {amountFormat(settlement?.settlement_amount)}
                </div>,
                <div className=" truncate">{settlement?.adjustment}</div>,
                <div className=" truncate">
                  {amountFormat(settlement?.net_settlement_amount)}
                </div>,

                <div className=" truncate">
                  {new Date(settlement?.payment_from).toLocaleDateString(
                    "en-US",
                    {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    },
                  )}
                </div>,
                <div className=" truncate">
                  {/* {settlement?.Till} */}
                  {new Date(settlement?.payment_till).toLocaleDateString(
                    "en-US",
                    {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    },
                  )}
                </div>,
                <div className=" truncate">{settlement?.status}</div>,
                <div
                  className=" truncate overflow-hidden"
                  style={{ maxWidth: "5em" }}
                  title={settlement?.utr}
                >
                  {settlement?.utr}
                </div>,
                <div className="truncate">
                  {new Date(settlement?.settled_on).toLocaleDateString(
                    "en-US",
                    {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    },
                  )}
                </div>,
              ]) || []),
          ]}
          footer={
            <div className="flex justify-center items-center">
              <Pagination
                currentPage={currentPage}
                totalPages={data?.getAllVendorSettlementReport?.totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          }
        />
      )}
    </div>
  );
}

export default VendorSettlement;
