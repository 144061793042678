export const createRanges = (arr: []) => {
  let start = 0;
  let ranges = [];

  for (let end of arr) {
    ranges.push(`${start}-${end}`);
    start = end;
  }

  return ranges;
};
