import React from "react";
import { Route, Routes } from "react-router-dom";
import AllSchoolList from "./AllSchoolList";
import Vendor from "./Vendor";

function Institute() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<AllSchoolList />} />
        <Route path="/Vendor" element={<Vendor />} />
      </Routes>
    </div>
  );
}

export default Institute;
