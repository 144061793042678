import React, { useContext, useEffect, useState } from "react";
import { _Table } from "../../../../../components/Table";
import { dashboardContext } from "../../../Dashboard";
import { addValueToObject } from "../../../../../utils/addValueToObject";
import { toast } from "react-toastify";
import { preventNegativeValues } from "../../../../../utils/preventNegativeValues";
import { preventPasteNegative } from "../../../../../utils/preventPasteNegative";
import Modal from "../../../../../components/Modal/Modal";
import TaxInvoiceTemplate from "../CurrentCargeTab/TaxInvoiceTemplate";
import { useMutation } from "@apollo/client";
import { GET_INVOICES, UPLOAD_INVOICE } from "../../../../../Qurries";
function SelectCommission({ setOpen, invoiceDates }: any) {
  const { transactionReport, commissionReport } = useContext(dashboardContext);
  const [monthlyCommission, setMonthlyCommission] = useState<any>([]);
  const [selectedCheckbox, setSelectedCheckbox] = useState<any>(null);
  const [invoiceDetails, setInvoiceDetails] = useState<any>({});
  const [uploadInvoice, setUploadInvoice] = useState(false);
  const [selectedImage, setSelectedImage] = useState<any>("");
  const [invoiceNumber, setInvoiceNumber] = useState("");

  const [upload_invoice] = useMutation(UPLOAD_INVOICE, {
    refetchQueries: [
      {
        query: GET_INVOICES,
        variables: {
          page: 1,
          limit: 100,
        },
      },
    ],
  });
  const [generateInvoiceModal, setGenerateInvoiceModal] = useState(false);
  const handleCheckboxChange = (index: number) => {
    setSelectedCheckbox(index);
  };

  const curruntMonth = new Date().getMonth();

  const months: any = {
    0: { month: "01 Jan 2024 - 31 Jan 2024", commission: [] },
    1: { month: "01 Feb 2024 - 29 Feb 2024", commission: [] },
    2: { month: "01 Mar 2024 - 31 Mar 2024", commission: [] },
    3: { month: "01 Apr 2024 - 30 Apr 2024", commission: [] },
    4: { month: "01 May 2024 - 31 May 2024", commission: [] },
    5: { month: "01 Jun 2024 - 30 Jun 2024", commission: [] },
    6: { month: "01 Jul 2024 - 31 Jul 2024", commission: [] },
    7: { month: "01 Aug 2024 - 31 Aug 2024", commission: [] },
    8: { month: "01 Sep 2024 - 30 Sep 2024", commission: [] },
    9: { month: "01 Oct 2024 - 31 Oct 2024", commission: [] },
    10: { month: "01 Nov 2024 - 30 Nov 2024", commission: [] },
    11: { month: "01 Dec 2024 - 31 Dec 2024", commission: [] },
  };

  const mergeValueInSingleArray = (arr: any) => {
    const arr1: any = [];

    for (let index = 0; index < 12; index++) {
      if (index !== curruntMonth) {
        const element = arr[index];
        if (element.commission?.length) {
          arr1.push(element);
        }
      }
    }
    return arr1;
  };
  useEffect(() => {
    const monthWiseCommission = () => {
      commissionReport?.fetchAllCommission?.map((t: any) => {
        if (t?.commission_amount > 0) {
          return addValueToObject(
            months,
            new Date(Number(t?.createdAt)).getMonth(),
            t?.commission_amount,
          );
        } else {
          return null;
        }
      });
    };
    monthWiseCommission();
    setMonthlyCommission(mergeValueInSingleArray(months));
  }, []);

  const amountWithoutGst = (amount: number) => {
    let AMOUNT_WITHOUT_GST = amount / (1 + 18 / 100);
    let tax = (amount - AMOUNT_WITHOUT_GST).toFixed(2);
    let total = amount.toFixed(2);
    return { AMOUNT_WITHOUT_GST: AMOUNT_WITHOUT_GST.toFixed(2), tax, total };
  };
  const validFileTypes = ["application/pdf"];
  const maxSize = 10 * 1024 * 1024;

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];

    if (file) {
      const file = e.target.files[0];

      if (!validFileTypes.find((type) => type === file.type)) {
        return toast.warning("File must be in PDF format");
      }
      if (file.size > maxSize) {
        return toast.warning("file size is larger than 10MB");
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e?.target?.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Modal
        className="w-full max-w-7xl"
        open={generateInvoiceModal}
        setOpen={setGenerateInvoiceModal}
      >
        <TaxInvoiceTemplate
          setGenerateInvoiceModal={setGenerateInvoiceModal}
          invoiceDetails={invoiceDetails}
        />
      </Modal>
      <div className=" bg-white rounded-md px-4 py-6 m-4">
        <_Table
          boxPadding={" p-0"}
          minHeight={" h-auto"}
          heading={"Select Commissions"}
          bgColor=" bg-gray-50"
          data={[
            ["", "Commission Period", "Commission Amount", "Tax"],
            ...monthlyCommission?.map((d: any, index: number) => [
              <div>
                <input
                  checked={selectedCheckbox === index}
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      handleCheckboxChange(index);
                      setInvoiceDetails({
                        month: d?.month,
                        details: amountWithoutGst(
                          d?.commission.reduce((a: any, b: any) => a + b, 0),
                        ),
                      });
                    } else {
                      setSelectedCheckbox(null);
                      setInvoiceDetails({});
                    }
                  }}
                  type="checkbox"
                  id={d?.month}
                />
              </div>,
              <div>{d.month}</div>,
              <div>
                ₹
                {
                  amountWithoutGst(
                    d?.commission.reduce((a: any, b: any) => a + b, 0),
                  ).AMOUNT_WITHOUT_GST
                }
              </div>,
              <div>
                ₹
                {
                  amountWithoutGst(
                    d?.commission.reduce((a: any, b: any) => a + b, 0),
                  ).tax
                }
              </div>,
            ]),
          ]}
          footer={
            <>
              {selectedCheckbox !== null && (
                <>
                  <div className="p-4 pb-0">
                    <ul className=" space-y-1 my-2 ml-4">
                      <li className="text-black text-sm font-medium">
                        Total Commission Amount: ₹
                        {invoiceDetails?.details?.AMOUNT_WITHOUT_GST}
                      </li>
                      <li className="text-black text-sm font-medium">
                        Total Tax Amount: ₹{invoiceDetails?.details?.tax}
                      </li>
                      <li className="text-black text-sm font-medium">
                        Total Invoice Amount: ₹{invoiceDetails?.details?.total}
                      </li>
                      <li className="text-black text-sm font-medium">
                        Note: TDS, if applicable,will be deducted from final
                        invoice amount
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </>
          }
        />
      </div>
      <>
        {selectedCheckbox !== null && (
          <div className=" bg-white rounded-md space-y-4 px-4 py-6 m-4">
            <>
              {!uploadInvoice ? (
                <>
                  <div className="flex items-center gap-x-3">
                    <button
                      onClick={() => setUploadInvoice(true)}
                      className="px-4 py-2 flex-none  bg-edviron_black rounded-md text-white font-medium text-sm"
                    >
                      Upload Invoice
                    </button>
                    <div>
                      <p className=" text-[11px] font-medium text-gray-500">
                        Use this action if you have created an invoice in your
                        own accounting system and want to upload it.
                      </p>
                      <p className="text-[11px] font-medium text-gray-500">
                        Use this action if you have created an invoice in your
                        own accounting system and want to upload it.
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center gap-x-3">
                    <button
                      onClick={() => setGenerateInvoiceModal(true)}
                      className="px-4 py-2 flex-none  bg-edviron_black rounded-md text-white font-medium text-sm"
                    >
                      Generate Invoice
                    </button>
                    <div>
                      <p className=" text-[11px] font-medium text-gray-500">
                        Use this action if you need help creating an invoice
                        using cashfrees invoice generation utility.
                      </p>
                      <p className="text-[11px] font-medium text-gray-500">
                        Note: Unregistered businessed or GST registered
                        businesses not falling under e-invoicing can use this
                        utility
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <div>
                  <label className="block">
                    <span className="sr-only">Choose File</span>
                    <input
                      type="file"
                      onChange={(e) => handleImageChange(e)}
                      className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-edviron_black hover:file:bg-violet-100"
                    />
                  </label>
                  <div className="flex mt-4 gap-y-2 flex-col">
                    <label
                      htmlFor="invoiceNo"
                      className=" text-gray-500 font-medium text-[12px]"
                    >
                      Invoice No. *
                    </label>
                    <input
                      className=" rounded-md max-w-md w-full px-4 py-1 focus:outline-none border border-gray-400"
                      type="text"
                      name="invoiceNo"
                      id="invoiceNo"
                      onChange={(e) => setInvoiceNumber(e.target.value)}
                      onKeyDown={preventNegativeValues}
                      onPaste={preventPasteNegative}
                    />
                    <div className=" flex">
                      <button
                        onClick={async () => {
                          try {
                            const res = await upload_invoice({
                              variables: {
                                base64: selectedImage,
                                invoice_no: invoiceNumber,
                                duration: invoiceDetails.month,
                              },
                            });
                            if (res?.data?.uploadInvoice) {
                              setOpen(false);
                              toast.success(res?.data?.uploadInvoice);
                            }
                          } catch (error) {
                            console.log(error);
                          }
                        }}
                        disabled={!selectedImage}
                        className="px-4 py-1.5 flex-none max-w-fit   bg-edviron_black disabled:bg-gray-600 rounded-md text-white font-medium text-sm"
                      >
                        Approve and Send Invoice
                      </button>
                      <button
                        onClick={() => setUploadInvoice(false)}
                        className="px-4 ml-2 py-1.5 flex-none max-w-fit border   border-edviron_black  rounded-md text-black font-medium text-sm"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        )}
      </>
    </>
  );
}

export default SelectCommission;
