import React, { useEffect, useState } from "react";
import { isValidNumber, preventNegativeValues } from "../../BulkRangeCard";
import { useMutation } from "@apollo/client";
import { CREATE_MDR_REQUEST } from "../../../../../Qurries";
import { preventPasteNegative } from "edviron-ui-package";
import { Select } from "edviron-ui-package";
import { createRanges } from "../../../../../utils/createRange";
import { toast } from "react-toastify";
const RangeCard = ({
  onDelete,
  cardIndex,
  cards,
  disabled = false,
  breakPoint,
  fees,
  setFees,
  setBreakPoint,
  type,
  setType,
  amountType,
  setAmountType,
  upto,
  from,
  setAddRangeError,
  cardsLength,
  charge,
}: any) => {
  const error =
    (cardIndex === 0 &&
      cards.length > 1 &&
      (breakPoint[cardIndex] <= 0 || !isValidNumber(breakPoint[cardIndex]))) ||
    (cardIndex > 0 &&
      cardIndex < cards.length &&
      Number(breakPoint[cardIndex - 1]) >= Number(breakPoint[cardIndex]));
  const error1 =
    cardIndex < cards.length - 1 && isNaN(Number(breakPoint[cardIndex]));

  return (
    <div>
      <div className="flex justify-between items-center">
        <h3 className=" text-sm font-medium text-edvion_black">
          Charge {cardIndex + 1}
        </h3>
        <h3 className=" text-sm font-medium text-edvion_black">Base Rate</h3>
      </div>
      <div className="flex justify-between gap-4 items-center mt-4">
        <div className="flex items-end gap-4 min-w-[16rem] max-w-[20rem]">
          <input
            type="number"
            name="initialRange"
            disabled={true}
            value={cardIndex === 0 ? from : breakPoint[cardIndex - 1]}
            placeholder="set initial range"
            min={cardIndex === 0 ? 0 : breakPoint[cardIndex - 1]}
            max={breakPoint[cardIndex - 1]}
            className="rounded-lg border text-[#000000CC] text-xs border-[#959595] px-4 py-1.5 w-full focus:outline-none placeholder:text-[#00000066] placeholder:text-xs "
          />

          <input
            type="text"
            name="finalRange"
            pattern="/^[0-9∞]*$/"
            onChange={(e) => {
              const newValue = e.target.value;
              if (/^[0-9∞]*$/.test(newValue)) {
                const updatedBreakPoint = breakPoint.map(
                  (value: any, i: any) => {
                    if (i === cardIndex) {
                      if (Number(newValue) > upto) {
                        return (e.target.value = upto);
                      } else {
                        return Number(newValue);
                      }
                    }
                    if (i === cardsLength - 1) {
                      if (upto == "∞") return null;
                      return upto;
                    } else {
                      return value;
                    }
                  }
                );
                setBreakPoint(updatedBreakPoint);
              }
            }}
            disabled={disabled || cardIndex === cards.length - 1}
            placeholder="set final range"
            value={
              cardIndex === cards.length - 1
                ? upto === null
                  ? "∞"
                  : upto
                : breakPoint[cardIndex]
            }
            max={upto !== null ? upto : upto}
            className={
              "rounded-lg border text-[#000000CC] text-xs border-[#959595] px-4 py-1.5 w-full focus:outline-none placeholder:text-[#00000066] placeholder:text-xs " +
              ((error || error1) && " border border-red-500")
            }
          />
        </div>
        <div className="items-center flex gap-x-2">
          <div className="w-20">
            <input
              type="number"
              name="amount"
              value={charge}
              // onChange={(e) =>
              //   setFees(
              //     fees.map((v: any, i: number) => {
              //       if (i === cardIndex) {
              //         return e.target.value;
              //       } else {
              //         return fees[i];
              //       }
              //     })
              //   )
              // }
              disabled={true}
              placeholder="₹"
              className={
                "rounded-lg border text-[#000000CC] text-xs border-[#959595] px-4 py-1.5 w-full focus:outline-none placeholder:text-[#00000066] placeholder:text-xs placeholder:text-right "
              }
              min={0}
              onKeyDown={preventNegativeValues}
              onPaste={preventPasteNegative}
            />
          </div>
          <div>
            <input
              disabled={true}
              value={type}
              className={
                "rounded-lg border min-w-[5rem] text-[#000000CC] text-xs border-[#959595] px-4 py-1.5 w-full focus:outline-none placeholder:text-[#00000066] placeholder:text-xs placeholder:text-right "
              }
            />
          </div>
        </div>
      </div>
      <div className="flex justify-end mt-2">
        {!disabled && cards.length > 1 && cards.length === cardIndex + 1 && (
          <button
            className="text-[#6F6AF8] text-xs font-medium"
            onClick={onDelete}
          >
            Delete
          </button>
        )}
      </div>
    </div>
  );
};
function TrusteeRangeCard({
  card_data = [1],
  schoolId,
  range,
  setPaymentRange,
  cardNumber,
  handleClick,
  enabledIndex,
  setEnabledIndex,
  rangeArray,
  platform_type,
  payment_mode,
  setfinalPaymentInfo,
  setItems,
  setSaveButton,
}: any) {
  const [cards, setCards] = useState<any>([1]);
  const [breakPoint, setBreakPoint] = useState<any>([]);
  const [fees, setFees] = useState<any>([0]);
  const [isValidInput, setIsValidInput] = useState<boolean>(false);

  const [type, setType] = useState<any>(range?.charge_type);
  const [addButton, setAddButton] = useState(false);
  useEffect(() => {
    setCards(card_data);
    setBreakPoint([range?.upto]);
    setFees([range?.charge]);
  }, []);

  useEffect(() => {
    setIsValidInput(
      !breakPoint
        .map((c: any, i: number) => {
          if (i === 0) {
            return isValidNumber(breakPoint[i]) && breakPoint[i] > 0;
          } else {
            return (
              isValidNumber(breakPoint[i - 1]) &&
              isValidNumber(breakPoint[i]) &&
              Number(breakPoint[i - 1]) < Number(breakPoint[i])
            );
          }
        })
        .includes(false)
    );
  }, [breakPoint, fees]);

  const addCard = () => {
    setAddButton(true);
    setCards([...cards, cards.length]);
    if (cards.length > 0) {
      setBreakPoint([...breakPoint, ""]);
    }
    if (cards.length > 0) setFees([...fees, 0]);
  };
  const deleteCard = (index: any) => {
    setAddButton(false);
    setCards(cards.filter((cardIndex: any, i: any) => i !== index));
    setBreakPoint(breakPoint.slice(0, breakPoint.length - 1));
    setFees(fees.slice(0, fees.length - 1));
  };

  return (
    <div className="h-[300px] bg-white shadow rounded-md p-4 flex flex-col justify-between">
      <div>
        <div className="flex justify-between items-center mb-8">
          <button
            //disabled={addRangeError}
            className="capitalize text-[#6F6AF8] text-xs font-medium"
            onClick={addCard}
          >
            + add new range
          </button>
          <p className="text-xs font-normal text-edviron_black">
            You Can Add Range Between {range?.from} to {range?.upto ?? "∞"}
          </p>
        </div>
        <div className="space-y-4 overflow-hidden overflow-y-auto h-[180px] pr-6">
          {cards.map((cardIndex: any, index: any) => (
            <RangeCard
              breakPoint={breakPoint}
              setBreakPoint={setBreakPoint}
              type={type}
              setType={setType}
              key={index}
              cards={cards}
              fees={fees}
              setFees={setFees}
              cardIndex={index}
              onDelete={() => deleteCard(index)}
              upto={range?.upto ?? "∞"}
              from={range?.from}
              charge={range?.charge}
              cardsLength={cards.length}
            />
          ))}
        </div>
      </div>
      <div className="flex justify-end gap-x-2">
        <button
          disabled={!addButton}
          onClick={async () => {
            handleClick(cardNumber);
            breakPoint.push(null);
            let range_charge: any = [];
            fees.map((fee: any, index: number) => {
              let upto = breakPoint[index]
                ? parseInt(breakPoint[index], 10)
                : null;
              range_charge.push({
                id: cardNumber,
                upto: upto,
                charge: range?.charge,
                charge_type: type,
              });
            });

            setPaymentRange((prev: any) => {
              if (prev !== undefined || null) {
                return [...prev, ...range_charge];
              } else {
                return range_charge;
              }
            });
            setSaveButton(true);
            setAddButton(false);
            toast.success(
              "New Range Added  " +
                createRanges(range_charge.map((d: any) => d.upto)).join("->")
            );
          }}
          className="rounded-lg disabled:bg-[#D9D9D978] disabled:text-[#959595] bg-blue-700 text-white px-8 py-1 max-w-fit"
        >
          Add
        </button>
      </div>
    </div>
  );
}

export default TrusteeRangeCard;
