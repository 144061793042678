/* eslint-disable no-restricted-globals */
import React, { useState } from "react";
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Modal from "./Modal";
import ConfirmationBox from "../ConfermationBox";
function RateChargeModal({ open, setOpen, children, hasChanged }: any) {
  const [openModal, setModal] = useState(false);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => {
          if (hasChanged === true) {
            setModal(true);
          } else {
            setOpen(false);
          }
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <Modal open={openModal} setOpen={setModal}>
            <ConfirmationBox
              setOpen={setModal}
              funtion={async () => {
                setOpen(false);
              }}
              confirmationText="Changes you make will not be saved."
              closeText="Changes you make will not be saved."
              ButtonText="Close"
            />
          </Modal>
          <div className="flex h-full md:justify-end items-start  p-0 ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform w-full  md:max-w-[50%] h-full  bg-[#EDF1F4] text-left shadow transition-all`}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default RateChargeModal;
