import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
// import { Modal } from "edviron-ui-package";
import Modal from "../../../../components/Modal/Modal";
import { CheckboxInput, Form, Input } from "edviron-ui-package";
import {
  CREATE_WEBHOOK,
  GET_WEBHOOKS,
  DELETE_WEBHOOK,
} from "../../../../Qurries";
import { _Table } from "../../../../components/Table";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import ConfirmationBox from "../../../../components/ConfermationBox";
import { FaFileCode } from "react-icons/fa";

const DeleteWebhookUrl = ({
  user_id,
  refetch,
  setShowDeleteModal,
  url,
}: any) => {
  const [
    DeleteWebhook,
    { loading: delete_webhook_loading, error: delete_webhook_error },
  ] = useMutation(DELETE_WEBHOOK);

  return (
    <ConfirmationBox
      setOpen={setShowDeleteModal}
      funtion={async () => {
        const res = await DeleteWebhook({
          variables: {
            webHook_id: user_id,
          },
        });

        if (res?.data.deleteWebhook.includes("Webhook deleted successfully")) {
          toast.success("User access removed successfully");
          refetch();
        }
      }}
      confirmationText={`Remove Webhook ${url}`}
      ButtonText="Remove"
    />
  );
};

function Webhook() {
  const [showModal, setShowModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [ShowCodeSnippet, setShowCodeSnippet] = React.useState(false);
  const [webhookId, setWebhookIs] = React.useState(null);
  const [webhookUrl, setWebhookUrl] = React.useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const { data, loading, error, refetch } = useQuery(GET_WEBHOOKS);
  const webkoohList = data && data.getWebhooks ? data.getWebhooks : [];
  const commentText =
    "//aditional_data is in json string format you can convert it using JSON.parse()";
  const jsonText = {
    student_details: {
      student_id: "s123456",
      student_email: "testing",
      student_name: "test name",
      receipt: "r12",
    },
    additional_fields: { uid: "11111" },
  };
  const [
    CreateWebhooks,
    { loading: create_webhook_loading, error: create_webhook_error },
  ] = useMutation(CREATE_WEBHOOK);
  console.log(
    [...webkoohList]?.sort((a: any, b: any) => b.id - a.id),
    "sort",
  );

  const [
    DeleteWebhook,
    { loading: delete_webhook_loading, error: delete_webhook_error },
  ] = useMutation(DELETE_WEBHOOK);

  return (
    <div className="p-4  w-full shadow rounded-md mt-4 text-left bg-[#F6F8FA]">
      <div className="flex-1 flex flex-col items-center">
        {data?.getWebhooks ? (
          <_Table
            className="bg-[#ffffff]"
            heading={"Webhooks"}
            perPage={true}
            pagination={true}
            filter={[searchQuery]}
            copyRight={false}
            searchBox={
              <div className="flex justify-end items-center gap-x-2 w-full">
                <div className="bg-[#EEF1F6] py-3 items-center flex  px-6 max-w-md w-full rounded-lg">
                  <i className="text-edvion_black text-opacity-50 fa-solid text-xs fa-magnifying-glass"></i>
                  <input
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                    type="text"
                    placeholder="Search"
                    className="ml-4 text-xs focus:outline-none w-full placeholder:font-normal bg-[#EEF1F6]"
                  />
                </div>
                <div className="flex ml-auto">
                  <button
                    onClick={() => setShowModal(!showModal)}
                    className="py-2 bg-[#6687FF] text-sm rounded-[4px] text-white float-right px-6 ml-2"
                  >
                    + Add Webhook
                  </button>
                  <button
                    onClick={() => {
                      setShowCodeSnippet(!ShowCodeSnippet);
                    }}
                  >
                    <FaFileCode
                      className="text-[1.8rem] pl-3 text-[#6687FF] "
                      title="sample payload"
                    />
                  </button>
                </div>
              </div>
            }
            data={[
              ["S. No.", "Webhook Url", "Events", "Action"],
              ...[...webkoohList]
                ?.sort((a: any, b: any) => b.id - a.id)
                .filter((d: any) => {
                  const arr = [d.url].join(",");
                  return arr.toLowerCase().includes(searchQuery.toLowerCase());
                })
                .map((webkoohList: any, index: number) => [
                  <div className="ml-4">{index + 1}</div>,
                  <div className="trucate">{webkoohList.url}</div>,
                  <div>Success , failure</div>,
                  <div className="truncate">
                    <button
                      onClick={() => {
                        setShowDeleteModal(true);
                        setWebhookIs(webkoohList.id);
                      }}
                    >
                      <MdDelete className="text-[1.2rem]" />
                    </button>
                  </div>,
                ]),
            ]}
          />
        ) : (
          <div></div>
        )}
      </div>

      <Modal
        className="max-w-lg w-full"
        open={showModal}
        setOpen={setShowModal}
        title="Create WebHook"
      >
        <Form
          onSubmit={async (data: any) => {
            const regex = /^https:\/\//;

            if (regex.test(data.URL)) {
              const res = await CreateWebhooks({
                variables: {
                  webhookUrl: data?.URL,
                },
              });
              if (res?.data?.createWebhooks) {
                toast.success(res?.data?.createWebhooks);
                setShowModal(!showModal);
                refetch();
              }
            } else {
              toast.error("Only https links are supported");
            }
          }}
        >
          <Input
            type="url"
            placeholder="Enter WebHook URL"
            name="URL"
            value="https://"
            //   add_error={() => {}}
            required
          />
          <span className="text-[10px] text-[#717171]">
            Only https links are supported
          </span>
          {/* <Input
                        type="name"
                        placeholder="Enter WebHook URL"
                        name="URL Used For"
                        //   add_error={() => {}}
                        required
                        disabled
                        value="success, failure"
                    /> */}
          <label className="pt-2 pb-2 font-[500]">Events</label>
          <div className="">
            <label className="m-2">
              <input
                type="checkbox"
                checked={true}
                onChange={() => {}} // Disable editing
                disabled
              />
              <span className="pl-1">Payment Success</span>
            </label>
            <label className="m-2">
              <input
                type="checkbox"
                checked={true}
                onChange={() => {}} // Disable editing
                disabled
              />
              <span className="pl-1">Payment Failure</span>
            </label>
          </div>
          <div className="mt-2 mb-2 pt-2 text-center">
            <button className="py-2 px-1 max-w-[15rem] w-full rounded-lg disabled:bg-blue-300 bg-[#6687FF] text-white">
              Create Webhook
            </button>
          </div>
        </Form>
      </Modal>
      <Modal
        open={ShowCodeSnippet}
        setOpen={setShowCodeSnippet}
        className="max-w-2xl"
        title="Payload"
      >
        <div>
          <div className="p-4">success</div>
          <div className="bg-[#303B42] p-10 mb-4">
            <code className="font-mono bg-[#1B163B] text-xs md:text-sm lg:text-base text-white">
              <div className="blink">
                {/* <span className="mr-2 text-[#FFCB6B]">const</span>
                                <span className="mr-2 text-[#AFD183]">coder</span>
                                <span className="mr-2 ">=</span> */}
                <span className="text=[#AFD183]">{"{"}</span>
              </div>
              <div>
                <span className="ml-4 lg:ml-8 mr-2 text-[#e95b73]">
                  "collect_id":
                </span>
                <span className="text-[#e98f5b]">
                  "66099e5f11a775b1834564f4"
                </span>
                <span className="">,</span>
              </div>
              <div>
                <span className="ml-4 lg:ml-8 mr-2 text-[#e95b73]">
                  "amount":
                </span>
                <span className="text-[#75ff8c]">1</span>
                <span className="">,</span>
              </div>
              <div>
                <span className="ml-4 lg:ml-8 mr-2 text-[#e95b73]">
                  "status":
                </span>
                <span className="text-[#e98f5b]">"SUCCESS"</span>
                <span className="">,</span>
              </div>
              <div>
                <span className="ml-4 lg:ml-8 mr-2 text-[#e95b73]">
                  "trustee_id":
                </span>
                <span className="text-[#e98f5b]">
                  "65d43e124174f07e3e3f8967"
                </span>
                <span className="">,</span>
              </div>
              <div>
                <span className="ml-4 lg:ml-8 mr-2 text-[#e95b73]">
                  "school_id":
                </span>
                <span className="text-[#e98f5b]">
                  "65d443168b8aa46fcb5af3e4"
                </span>
                <span className="">,</span>
              </div>
              <div>
                <span className="ml-4 lg:ml-8 mr-2 text-[#e95b73]">
                  "req_webhook_urls":
                </span>
                <span className="text-[#e98f5b]">
                  [ "https://webhook.site/89d980b7-4ec7-40e7-8920-3df44e1cbfc4",
                  "def",
                  "https://webhook.site/58acb7e1-a051-4ff4-ae9e-dd24146afd29" ]
                </span>
                <span className="">,</span>
              </div>
              <div>
                <span className="ml-4 lg:ml-8 mr-2 text-[#e95b73]">
                  "custom_order_id":
                </span>
                <span className="text-[#e98f5b]">""</span>
                <span className="">,</span>
              </div>
              <div>
                <span className="ml-4 lg:ml-8 mr-2 text-[#e95b73]">
                  "createdAt":
                </span>
                <span className="text-[#e98f5b]">
                  "2024-09-18T10:11:39.630Z"
                </span>
                <span className="">,</span>
              </div>
              <div>
                <span className="ml-4 lg:ml-8 mr-2 text-[#e95b73]">
                  "transaction_time":
                </span>
                <span className="text-[#e98f5b]">
                  "2024-09-18T10:12:01.452Z"
                </span>
                <span className="">,</span>
              </div>
              <div>
                <span className="ml-4 lg:ml-8 mr-2 text-[#e95b73]">
                  "additional_data":
                </span>
                <span className="text-[#e98f5b]">
                  {JSON.stringify(JSON.stringify(jsonText))}{" "}
                  <span className="text-gray-500">{commentText}</span>
                </span>
              </div>
              <div className="flex ">
                <span className="ml-4 lg:ml-8 mr-2 text-[#e95b73]">"jwt":</span>
                <span className="truncate text-[#e98f5b]">
                  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2xsZWN0X2lkIjoiNjYwOTllNWYxMWE3NzViMTgzNDU2NGY0IiwiYW1vdW50IjoxLCJzdGF0dXMiOiJTVUNDRVNTIn0.c_-xvtgOZ4oSRdE5WoDqhsO_4RNU9rv-YZQRrZzFRmE"
                </span>
                <span className="">"</span>
              </div>

              <div>
                <span className="">{`}`}</span>
              </div>
            </code>
          </div>
          <div className="p-4">failure</div>
          <div className="bg-[#303B42] p-10">
            <code className="font-mono bg-[#1B163B] text-xs md:text-sm lg:text-base text-white">
              <div className="blink">
                {/* <span className="mr-2 text-[#FFCB6B]">const</span>
                                <span className="mr-2 text-[#AFD183]">coder</span>
                                <span className="mr-2 ">=</span> */}
                <span className="text=[#AFD183]">{"{"}</span>
              </div>
              <div>
                <span className="ml-4 lg:ml-8 mr-2 text-[#e95b73]">
                  "collect_id":
                </span>
                <span className="text-[#e98f5b]">
                  "66099e5f11a775b1834564f4"
                </span>
                <span className="">,</span>
              </div>
              <div>
                <span className="ml-4 lg:ml-8 mr-2 text-[#e95b73]">
                  "amount":
                </span>
                <span className="text-[#75ff8c]">1</span>
                <span className="">,</span>
              </div>
              <div>
                <span className="ml-4 lg:ml-8 mr-2 text-[#e95b73]">
                  "status":
                </span>
                <span className="text-[#e98f5b]">"PENDING"</span>
                <span className="">,</span>
              </div>
              <div>
                <span className="ml-4 lg:ml-8 mr-2 text-[#e95b73]">
                  "trustee_id":
                </span>
                <span className="text-[#e98f5b]">
                  "65d43e124174f07e3e3f8967"
                </span>
                <span className="">,</span>
              </div>
              <div>
                <span className="ml-4 lg:ml-8 mr-2 text-[#e95b73]">
                  "school_id":
                </span>
                <span className="text-[#e98f5b]">
                  "65d443168b8aa46fcb5af3e4"
                </span>
                <span className="">,</span>
              </div>
              <div>
                <span className="ml-4 lg:ml-8 mr-2 text-[#e95b73]">
                  "req_webhook_urls":
                </span>
                <span className="text-[#e98f5b]">
                  [ "https://webhook.site/89d980b7-4ec7-40e7-8920-3df44e1cbfc4",
                  "def",
                  "https://webhook.site/58acb7e1-a051-4ff4-ae9e-dd24146afd29" ]
                </span>
                <span className="">,</span>
              </div>
              <div>
                <span className="ml-4 lg:ml-8 mr-2 text-[#e95b73]">
                  "custom_order_id":
                </span>
                <span className="text-[#e98f5b]">""</span>
                <span className="">,</span>
              </div>
              <div>
                <span className="ml-4 lg:ml-8 mr-2 text-[#e95b73]">
                  "createdAt":
                </span>
                <span className="text-[#e98f5b]">
                  "2024-09-18T10:11:39.630Z"
                </span>
                <span className="">,</span>
              </div>
              <div>
                <span className="ml-4 lg:ml-8 mr-2 text-[#e95b73]">
                  "transaction_time":
                </span>
                <span className="text-[#e98f5b]">
                  "2024-09-18T10:12:01.452Z"
                </span>
                <span className="">,</span>
              </div>
              <div>
                <span className="ml-4 lg:ml-8 mr-2 text-[#e95b73]">
                  "additional_data":
                </span>
                <span className="text-[#e98f5b]">
                  {JSON.stringify(JSON.stringify(jsonText))}{" "}
                  <span className="text-gray-500">{commentText}</span>
                </span>
              </div>
              <div className="flex ">
                <span className="ml-4 lg:ml-8 mr-2 text-[#e95b73]">"jwt":</span>
                <span className="truncate text-[#e98f5b]">
                  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2xsZWN0X2lkIjoiNjYwOTllNWYxMWE3NzViMTgzNDU2NGY0IiwiYW1vdW50IjoxLCJzdGF0dXMiOiJTVUNDRVNTIn0.c_-xvtgOZ4oSRdE5WoDqhsO_4RNU9rv-YZQRrZzFRmE"
                </span>
                <span className="">"</span>
              </div>

              <div>
                <span className="">{`}`}</span>
              </div>
            </code>
          </div>
        </div>
      </Modal>
      <Modal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        className="max-w-2xl"
        title="Remove Access"
      >
        <DeleteWebhookUrl
          user_id={webhookId}
          refetch={refetch}
          setShowDeleteModal={setShowDeleteModal}
          url={webhookUrl}
        />
      </Modal>
    </div>
  );
}

export default Webhook;
