import { gql } from "@apollo/client";

export const SENT_KYC_INVITE = gql`
  mutation SentKycInvite($school_name: String!, $school_id: String!) {
    sentKycInvite(school_name: $school_name, school_id: $school_id)
  }
`;

export const LOGIN_TO_MERCHANT_WITH_TRUSTEE = gql`
  mutation GenerateMerchantLoginToken($email: String!) {
    generateMerchantLoginToken(email: $email)
  }
`;
