import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { GET_VENDOR_ALL_TRANSACTION } from "../../../../Qurries";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { _Table, Pagination } from "../../../../components/Table";
import { amountFormat } from "../../../../utils/amountFormat";

function VendorTransaction() {
  const [searchText, setSearchText] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const { data: vendorTransactions, loading } = useQuery(
    GET_VENDOR_ALL_TRANSACTION,
    {
      variables: {
        page: currentPage,
        limit: 10,
      },
    },
  );

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };

  return (
    <div>
      {loading ? (
        <AiOutlineLoading3Quarters className=" animate-spin text-xl" />
      ) : (
        <_Table
          perPage={true}
          exportBtn={true}
          heading={"Vendor Transactions"}
          copyContent={[4]}
          srNo={true}
          filter={[searchText]}
          searchBox={
            <div className="w-full ">
              <div className="flex xl:!flex-row flex-col justify-between gap-2  w-full xl:items-center items-start mb-2">
                <div className="bg-[#EEF1F6] py-3 items-center flex  px-6 xl:max-w-md max-w-[34rem] w-full rounded-lg">
                  <i className="text-edvion_black text-opacity-50 fa-solid text-xs fa-magnifying-glass"></i>
                  <input
                    className="ml-4 text-xs bg-transparent focus:outline-none w-full placeholder:font-normal"
                    onFocus={(e) =>
                      (e.target.style.borderColor = "transparent")
                    }
                    type="text"
                    placeholder=" Search(Name,Order ID...)"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          }
          data={[
            [
              "Name",
              "Date & Time",
              "Order ID",
              "school ID",
              "Transaction Amt",
              "Status",
            ],
            ...(vendorTransactions?.getAllVendorTransaction?.vendorsTransaction
              .filter((d: any) => {
                const arr = [
                  d.collect_id,
                  d.custom_id,
                  d.school_id,
                  d.name,
                ].join(",");
                return arr.toLowerCase().includes(searchText.toLowerCase());
              })
              .map((transaction: any, index: number) => [
                <div>{transaction?.name}</div>,
                <div>
                  {new Date(transaction?.createdAt).toLocaleString("hi")}
                </div>,
                <div>
                  {transaction.custom_id
                    ? transaction?.custom_id
                    : transaction?.collect_id}
                </div>,
                <div>{transaction.school_id}</div>,
                <div>{amountFormat(transaction.amount)}</div>,
                <div>{transaction.status}</div>,
              ]) || []),
          ]}
          footer={
            <div className="flex justify-center items-center">
              <Pagination
                currentPage={currentPage}
                totalPages={
                  vendorTransactions?.getAllVendorTransaction?.totalPages
                }
                onPageChange={handlePageChange}
              />
            </div>
          }
        />
      )}
    </div>
  );
}

export default VendorTransaction;
