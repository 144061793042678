import React, { useState } from "react";
import Modal from "../../../../components/Modal/Modal";

import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import { Form, Input } from "edviron-ui-package";
import { CREATE_INSTITUTE_IN_BULK, GET_SCHOOLS } from "../../../../Qurries";
// @ts-ignore
import schools from "../../../../assets/schools.csv";

const SchoolsUploadViaCsv: React.FC<{ page: number }> = ({ page }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isButtonActive, setIsButtonActive] = useState<boolean>(false);
  const [csvSchoolsList, setCsvSchoolsList] = useState<any[]>([]);

  const [
    createBulkTrusteeInstitutes,
    { data: result, loading: LOAD, error: _ERROR },
  ] = useMutation(CREATE_INSTITUTE_IN_BULK, {
    refetchQueries: [
      {
        query: GET_SCHOOLS,
        variables: { page },
      },
    ],
  });

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      toast.error("File not selected!");
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result as string;
      const cleanedText = text.replace(/\r/g, "");

      const rows = cleanedText.split("\n").map((row) => row.split(","));
      const headers = rows.shift();
      if (!headers) {
        return;
      }
      if (!file.name.endsWith(".csv")) {
        toast.error("Please select a CSV file!");
        return;
      }
      const trimmedHeaders = headers.map((header) => header.trim());
      const indices: { [key: string]: number } = {
        "School Name": trimmedHeaders.indexOf("school_name"),
        "School Email": trimmedHeaders.indexOf("email"),
        "Admin Name": trimmedHeaders.indexOf("admin_name"),
        "Phone Number": trimmedHeaders.indexOf("phone_number".trim()),
      };

      const extractedData = rows
        .filter((row) => row.length === trimmedHeaders.length)
        .map((row) => ({
          school_name: row[indices["School Name"]],
          email: row[indices["School Email"]],
          admin_name: row[indices["Admin Name"]],
          phone_number: row[indices["Phone Number"]].trim(), // Trim the phone number value
        }));
      setCsvSchoolsList(extractedData);
      setIsButtonActive(true);
    };

    reader.readAsText(file);
    setIsButtonActive(false);
  };

  const createInstitutesViaCsv = async (input: any[]) => {
    const { data } = await createBulkTrusteeInstitutes({
      variables: { schools: input },
    });
    toast.success(data?.createBulkTrusteeSchool);
  };

  return (
    <div>
      <button
        className="px-6 py-2 border border-edviron_black text-[#6687FF] text-sm rounded-[4px] w-full"
        onClick={() => setOpen(true)}
      >
        Bulk Upload
      </button>
      <Modal
        title="Upload CSV File ( Bulk Data )"
        open={open}
        setOpen={setOpen}
      >
        <div className="flex w-full">
          <div className="mx-auto">
            <Form
              onSubmit={async (data: any) => {
                await createInstitutesViaCsv(csvSchoolsList);
                setOpen(false);
              }}
              className=""
            >
              <Input
                add_error={() => {}}
                type="file"
                name="Upload CSV File"
                onChange={(e: any, v: React.ChangeEvent<HTMLInputElement>) => {
                  handleFileUpload(v);
                }}
                label="CSV File"
              />
              <a
                className="text-sm ml-auto text-[#6687FF] cursor-pointer"
                href={schools}
                download="SampleFile.csv"
              >
                Download Sample File
              </a>

              <div className="bg-edviron_black m-2 mt-6 text-white rounded-lg font-medium py-2 px-5">
                <button
                  type="submit"
                  className="w-full"
                  disabled={!isButtonActive}
                >
                  Upload
                </button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default SchoolsUploadViaCsv;
