import React, { useState } from "react";
import {
  GET_SINGLE_SCHOOL_MDR_INFO,
  TOGGLE_PAYMENT_MODE,
} from "../../../../../../Qurries";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

const ModeAction = ({ mode, schoolID, disableMode }: any) => {
  const [activePaymentMode, setActivePaymentMode] = useState(
    disableMode?.includes(mode)
  );
  const [paymentModeToggle] = useMutation(TOGGLE_PAYMENT_MODE, {
    refetchQueries: [GET_SINGLE_SCHOOL_MDR_INFO],
  });

  return (
    <div className="grid grid-cols-2">
      <div>
        <p className=" text-[#777777] text-xs font-normal uppercase">{mode}</p>
      </div>
      <div>
        <p className="flex space-x-2 items-center text-[#1B163B] text-xs font-normal">
          <div
            onClick={async () => {
              setActivePaymentMode(!activePaymentMode);
              const res = await paymentModeToggle({
                variables: {
                  school_id: schoolID,
                  mode,
                },
              });
              if (res?.data?.tooglePaymentMode) {
                toast.success(res?.data?.tooglePaymentMode);
              }
            }}
            className={
              "w-[45px] flex shrink-0 items-center cursor-pointer p-1 rounded-2xl  " +
              (activePaymentMode
                ? " justify-end deactiveStatus"
                : " justify-start activeStatus")
            }
          >
            <div className=" w-3 h-3 bg-white rounded-full"></div>
          </div>
          <span>{activePaymentMode ? "Inactive " : " Active"}</span>
        </p>
      </div>
    </div>
  );
};

export default ModeAction;
