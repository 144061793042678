import { useQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { GET_TRANSACTIONS_OF_SETTLEMENT } from "../../../Qurries";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { _Table } from "../../../components/Table";
import { amountFormat } from "../../../utils/amountFormat";
import { getSchoolFromId } from "../../../utils/getSchoolFromId";

function TransactionsOfSettlement() {
  let { state } = useLocation();

  const [currentCursor, setCurrentCursor] = useState<any>([""]);
  const [nextCursor, setNextCursor] = useState<any>("");
  const [refetchLoading, setRefetchLoading] = useState(false);
  const { data, loading, refetch } = useQuery(GET_TRANSACTIONS_OF_SETTLEMENT, {
    variables: {
      utr: state?.utrno,
      cursor: "",
      limit: 10,
    },
  });
  useEffect(() => {
    if (
      data?.getSettlementsTransactions?.cursor &&
      nextCursor !== data?.getSettlementsTransactions?.cursor &&
      data?.getSettlementsTransactions?.cursor !== null
    ) {
      setNextCursor(data?.getSettlementsTransactions?.cursor);
    }
  }, [data, nextCursor]);

  return (
    <div className="min-h-[80vh] rounded-lg mt-4 bg-[#F6F8FA] p-4">
      <div className="flex justify-between items-center">
        <h3 className="flex items-center text-lg font-semibold text-[#1B163B]">
          <Link to="/payments">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className=" h-6 mr-2 cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5 8.25 12l7.5-7.5"
              />
            </svg>
          </Link>
          Transactions Of Settlement
        </h3>
      </div>
      <div>
        {loading || refetchLoading ? (
          <div className="min-h-[60vh] w-full flex justify-center items-center">
            <AiOutlineLoading3Quarters className=" animate-spin text-xl" />
          </div>
        ) : (
          <_Table
            perPage={false}
            exportBtn={false}
            copyContent={[4]}
            data={[
              [
                "Sr.No",
                "Institute Name",
                "Date & Time",
                "Order ID",
                "Order Amt",
                "Transaction Amt",
                "Payment Method",
                "Status",
                "Student Name",
                "Phone No.",
              ],

              ...(data?.getSettlementsTransactions?.settlements_transactions.map(
                (transaction: any, index: number) => [
                  <div>{index + 1}</div>,
                  <div>{transaction.school_name}</div>,
                  <div>
                    {new Date(transaction?.event_time).toLocaleString("hi")}
                  </div>,
                  <div>
                    {transaction.custom_order_id
                      ? transaction.custom_order_id
                      : transaction.order_id}
                  </div>,
                  <div>{amountFormat(transaction?.order_amount)}</div>,
                  <div>{amountFormat(transaction?.event_amount)}</div>,
                  <div>{transaction?.payment_group}</div>,
                  <div
                    className={
                      " " +
                      (transaction.event_status.toLowerCase() === "success"
                        ? "text-[#04B521]"
                        : "")
                    }
                  >
                    {transaction?.event_status}
                  </div>,
                  <div>{transaction?.student_name}</div>,
                  <div>{transaction?.student_phone_no}</div>,
                ],
              ) || ["no more data"]),
            ]}
            footer={
              <div className="flex justify-between items-center my-4 px-4">
                <button
                  onClick={async () => {
                    setRefetchLoading(true);
                    if (currentCursor.length > 0) {
                      const newArray = currentCursor.slice(0, -1);
                      setCurrentCursor(newArray);
                    }

                    const res = await refetch({
                      utr: state?.utrno,
                      cursor: currentCursor[currentCursor.length - 2],
                      limit: 10,
                    });

                    if (res?.data) {
                      setRefetchLoading(false);
                    }
                  }}
                  disabled={currentCursor.length < 2}
                  className="px-4 py-1.5 text-sm rounded-lg disabled:bg-gray-400 bg-blue-600 text-white"
                >
                  Prev
                </button>
                <button
                  onClick={async () => {
                    setRefetchLoading(true);
                    if (data?.getSettlementsTransactions?.cursor !== null) {
                      setCurrentCursor((prev: any) => [
                        ...(prev || ""),
                        nextCursor,
                      ]);
                      const res = await refetch({
                        utr: state?.utrno,
                        cursor: nextCursor,
                        limit: 10,
                      });

                      console.log(
                        res?.data?.getSettlementsTransactions?.cursor,
                      );

                      if (res?.data) {
                        setRefetchLoading(false);
                      }
                    }
                  }}
                  disabled={data?.getSettlementsTransactions?.cursor === null}
                  className="px-4 py-1.5 text-sm rounded-lg disabled:bg-gray-400 bg-blue-600 text-white"
                >
                  Next
                </button>
              </div>
            }
          />
        )}
      </div>
    </div>
  );
}

export default TransactionsOfSettlement;
