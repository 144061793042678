import React, { useContext, useEffect, useState } from "react";
import { dashboardContext } from "../../../Dashboard";
import Select from "react-select";
import BulkRangeCard from "../../BulkRangeCard";
import TrusteeRangeCard from "./TrusteeRangeCard";
import { addFromValues } from "./Tabs/RateTab";
import Modal from "../../../../../components/Modal/Modal";
import { toast } from "react-toastify";
import {
  CREATE_MDR_REQUEST,
  GET_MDR_REQUESTS,
  GET_SCHOOLS,
} from "../../../../../Qurries";
import { useMutation, useQuery } from "@apollo/client";
import { resultFilter } from "../../../../../utils/filterObject";
import ConfirmationBox from "../../../../../components/ConfermationBox";

const _ = require("lodash");
function SingleSchoolRangeModal({
  schoolId,
  setOpen,
  setPaymentRange,
  paymentRange,
  isBulk,
  description,
  setfinalPaymentInfo,
  mainModal,
  items,
  setItems,
}: any) {
  const [paymentModes, setPayementModes] = useState<any>(null);
  const [selectedPaymentMode, setSelectedPaymentMode] = useState<any>(null);
  const [saveButton, setSaveButton] = useState(false);

  const { NEW_PLATFORM_TYPE, platform_type } = useContext(dashboardContext);
  const [createMdr] = useMutation(CREATE_MDR_REQUEST, {
    refetchQueries: [
      {
        query: GET_MDR_REQUESTS,
      },
    ],
  });

  // const platform_types = [
  //   "CreditCard",
  //   "DebitCard",
  //   "UPI",
  //   "NetBanking",
  //   "Wallet",
  //   "PayLater",
  //   "CardLess EMI",
  //   "INTERNATIONAL PG",
  //   "CORPORATE CARDS",
  // ];

  // const NEW_PLATFORM_TYPE = user?.getUserQuery?.base_mdr?.platform_charges.map(
  //   (d: any) => {
  //     if (platform_types.includes(d?.platform_type)) {
  //       return {
  //         label: d?.platform_type,
  //         value: [
  //           {
  //             label: d.payment_mode,
  //             value: d.payment_mode,
  //             range: d?.range_charge,
  //           },
  //         ],
  //       };
  //     }
  //   }
  // );

  // const platform_type = NEW_PLATFORM_TYPE.reduce((acc: any, current: any) => {
  //   const existing = acc.find((item: any) => item.label === current.label);
  //   if (existing) {
  //     existing.value = [...existing.value, ...current.value];
  //   } else {
  //     acc.push({
  //       label: current.label,
  //       value: [...current.value],
  //     });
  //   }
  //   return acc;
  // }, []);

  const filterdArray = addFromValues(
    _.sortBy(selectedPaymentMode?.range, (item: any) =>
      item.upto === null ? Infinity : item.upto
    )
  );
  const [enabledIndex, setEnabledIndex] = useState(0);
  const [confermationBox, setConfermationBox] = useState(false);
  const handleClick = (index: any) => {
    if (index === enabledIndex) {
      setEnabledIndex((prevIndex) => prevIndex + 1);
    }
  };

  const findMissingItems = (arr1: any, arr2: any) => {
    const arr2Ids = arr2.map((item: any) => item.id);
    return arr1.filter((item: any, index: any) => !arr2Ids.includes(index));
  };
  const filterRange = [
    ...findMissingItems(filterdArray, paymentRange),
    ...paymentRange,
  ];

  const mdr_request_arr = filterRange.map((r: any) => {
    return {
      charge: r?.charge,
      charge_type: r?.charge_type,
      upto: r?.upto,
    };
  });
  // const [items, setItems] = useState<any>([]);

  const addItem = (newItem: any) => {
    setItems((prevItems: any) => [...prevItems, newItem]);
  };

  // const finalRange = resultFilter(
  //   NEW_PLATFORM_TYPE?.map((mode: any) => {
  //     return {
  //       platform_type: mode?.label,
  //       payment_mode: mode?.value?.[0]?.label,
  //       range_charge:
  //         mode.value?.[0]?.range.map((range: any) => {
  //           return {
  //             charge: range?.charge,
  //             charge_type: range?.charge_type,
  //             upto: range?.upto,
  //           };
  //         }) ?? [],
  //     };
  //   }),
  //   items
  // ).concat(items);

  // useEffect(() => {
  //   if (items.length > 0) {
  //     setfinalPaymentInfo(finalRange);
  //   }
  // }, [items]);

  return (
    <div>
      <Modal open={confermationBox} setOpen={setConfermationBox}>
        <ConfirmationBox
          setOpen={setConfermationBox}
          funtion={async () => {
            addItem({
              platform_type: paymentModes?.label,
              payment_mode: selectedPaymentMode?.label,
              range_charge: mdr_request_arr,
            });
            setPaymentRange([]);
            setEnabledIndex(0);
            setOpen(false);
            // mainModal(false);
            // setTimeout(() => {
            //   mainModal(true);
            // }, 700);
          }}
          confirmationText="save the changes"
          ButtonText="Save"
        />
      </Modal>
      <Select
        value={paymentModes}
        options={platform_type}
        onChange={(selectedOption) => {
          setPayementModes(selectedOption);
          setSelectedPaymentMode(null);
        }}
        placeholder="Select Platform Type"
        styles={{
          container: (provided, state) => ({
            ...provided,
            marginBottom: "10px",
          }),
        }}
      />

      <Select
        options={paymentModes?.value}
        value={selectedPaymentMode}
        onChange={(selectedOption) => {
          setSelectedPaymentMode(selectedOption);
        }}
        placeholder="Select Payment Method"
        styles={{
          container: (provided, state) => ({
            ...provided,
            marginBottom: "10px",
          }),
        }}
      />

      {!selectedPaymentMode?.range.length
        ? ""
        : filterdArray.map((range: any, i: number) => {
            return (
              <div key={i} className="my-2">
                <TrusteeRangeCard
                  schoolId={[]}
                  platform_type={selectedPaymentMode}
                  payment_mode={paymentModes}
                  setOpen={setOpen}
                  range={range}
                  rangeArray={filterdArray}
                  cardNumber={i}
                  setPaymentRange={setPaymentRange}
                  paymentRange={paymentRange}
                  handleClick={handleClick}
                  enabledIndex={enabledIndex}
                  setEnabledIndex={setEnabledIndex}
                  setItems={setItems}
                  setSaveButton={setSaveButton}
                />
              </div>
            );
          })}

      <div className=" flex justify-end">
        <button
          disabled={!saveButton}
          onClick={() => setConfermationBox(true)}
          className="rounded-lg my-2 disabled:bg-[#D9D9D978] disabled:text-[#959595] bg-blue-700 text-white px-8 py-1 max-w-fit"
        >
          Save
        </button>
      </div>
      {/* <div className=" flex w-full my-4">
        <button
          disabled={
            isBulk
              ? !paymentRange?.length && !schoolId.length
              : !paymentRange?.length
          }
          className="rounded-lg w-full  disabled:bg-[#D9D9D978] disabled:text-[#959595] bg-blue-700 text-white px-8 py-1 max-w-[28rem] mx-auto"
          onClick={async () => {
            try {
              const res = await createMdr({
                variables: {
                  school_id: schoolId,
                  platform_charge: finalRange,
                  description: description,
                },
              });
              if (res?.data?.createMdrRequest) {
                toast.success(res?.data?.createMdrRequest);
                setOpen(false);
              }
            } catch (err) {
              setOpen(false);
            }
          }}
        >
          Create MDR
        </button>
      </div> */}
    </div>
  );
}

export default SingleSchoolRangeModal;
