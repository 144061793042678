import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import ApiKey from "./ApiKey";
import Webhook from "./Webhook";
import GeneratePgLink from "../../../GeneratePgLink/GeneratePgLink";
import CopyRight from "../../../../components/CopyRight";
function DevSideBar({ menu }: any) {
  const [activeTab, setActiveTab] = useState(1);

  return (
    <div className="flex flex-col min-h-screen -mt-20 pt-20">
      <div className="flex">
        <div
          className={
            "  transition-transform duration-200 overflow-hidden fixed top-10 shrink-0 pt-10 " +
            (menu ? "translate-x-0 w-56" : " -translate-x-[100%] w-0 ")
          }
        >
          <div className=" flex flex-col ">
            <button
              onClick={() => setActiveTab(1)}
              className={
                activeTab === 1
                  ? "bg-[#6687FF33] bg-opacity-20  py-1.5 font-semibold rounded-lg text-[14px] text-left pl-10 text-[#1B163B]"
                  : "  py-1.5 font-semibold rounded-lg text-[14px] text-left pl-10 text-[#717171]"
              }
            >
              API Key
            </button>
            <button
              onClick={() => setActiveTab(2)}
              className={
                activeTab === 2
                  ? "bg-[#6687FF33]   py-1.5 font-semibold rounded-lg text-[14px] text-left pl-10 text-[#1B163B]"
                  : " py-1.5 font-semibold rounded-lg text-[14px] text-left pl-10 text-[#717171]"
              }
            >
              Webhooks
            </button>
            <button
              onClick={() => setActiveTab(3)}
              className={
                activeTab === 3
                  ? "bg-[#6687FF33]   py-1.5 font-semibold rounded-lg text-[14px] text-left pl-10 text-[#1B163B]"
                  : " py-1.5 font-semibold rounded-lg text-[14px] text-left pl-10 text-[#717171]"
              }
            >
              PG-Link
            </button>
          </div>
        </div>
        <div
          className={
            "tab-content  w-full lg:pr-8 pr-4 ml-4 " + (menu ? " pl-56" : " ")
          }
        >
          {activeTab === 1 && <ApiKey />}
          {activeTab === 2 && <Webhook />}
          {activeTab === 3 && <GeneratePgLink />}
        </div>
      </div>
      <div className="mt-auto py-2">
        <CopyRight />
      </div>
    </div>
  );
}

export default DevSideBar;
