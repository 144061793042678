import React, { useState } from "react";

import axios from "axios";

import { toast } from "react-toastify";
import { useQuery } from "@apollo/client";
import { GET_ALL_SCHOOLS_QUERY } from "../../Qurries";
import { MdContentCopy } from "react-icons/md";
import { Form } from "edviron-ui-package";
import { preventPasteNegative } from "../../utils/preventPasteNegative";
import { preventNegativeValues } from "../../utils/preventNegativeValues";

import PgSchoolSelect from "./PgSchoolSelect/PgSchoolSelect";

const sign = require("jwt-encode");

const GeneratePgLink = () => {
  const [res, setRes] = useState<any>(null);

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("copied successfully");
      })
      .catch((error) => {
        toast.error("Unable to copy");
      });
  };

  const { data: schools } = useQuery(GET_ALL_SCHOOLS_QUERY);

  const [selectedSchool, setSelectedSchool] = useState<any>({
    name: "Select school",
    id: "",
    pg_key: "",
  });
  const [amount, setAmount] = useState<any>(null);
  const [callbackUrl, setCallbackUrl] = useState<any>("https://google.com");
  const [apiKey, setApiKey] = useState("");
  return (
    <div className="h-[90vh] bg-[#F6F8FA] p-[30px] mt-[20px] rounded-[20px]">
      <p className="text-[20px] text-[#1B163B] font-semibold">
        Generate Payment URL
      </p>
      <div className="grid  lg:grid-cols-2 gap-4 pb-[10px]">
        <div className="w-full h-[100%] p-[40px] lg:border-r-[0.5px] lg:border-b-0 border-r-0 border-b-[0.5px]">
          <p className="text-[14px] text-[#1B163B] font-[500] mb-[20px]">
            Provide Input
          </p>
          <Form
            onSubmit={async (data: any) => {
              if (selectedSchool.name === "Select school") {
                toast.error("Please select school");
                return;
              }
              if (!amount || amount === "") {
                toast.error("Please fill amount");
                return;
              }
              if (!callbackUrl || callbackUrl === "") {
                toast.error("Please fill callback url");
                return;
              }
              if (!apiKey || apiKey === "") {
                toast.error("Please fill API key");
                return;
              }

              const sign_val = sign(
                {
                  school_id: selectedSchool?.id,
                  amount: amount,
                  callback_url: callbackUrl,
                },
                selectedSchool?.pg_key,
              );
              try {
                const response = await axios.post(
                  `${process.env.REACT_APP_BACKEND_URL}/erp/create-collect-request`,
                  {
                    amount,
                    school_id: selectedSchool?.id,
                    callback_url: callbackUrl,
                    sign: sign_val,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${apiKey}`,
                      "Content-Type": "application/json",
                    },
                  },
                );

                if (response?.data) {
                  setRes(response?.data?.collect_request_url);
                  toast.success("Link generate successfully");
                }
              } catch (error: any) {
                if (error?.response?.data?.message) {
                  toast.error(error?.response?.data?.message);
                }
              }
            }}
          >
            <PgSchoolSelect
              options={
                schools?.getAllSchoolQuery?.map((school: any) => {
                  return {
                    name: school.school_name,
                    id: school.school_id,
                    pg_key: school.pg_key,
                  };
                }) || []
              }
              selected={selectedSchool}
              setSelected={setSelectedSchool}
              label="Select school"
              labelStyle=" text-xs"
              className="w-[90%]"
            />
            {selectedSchool?.pg_key !== null ? (
              <>
                <div className="relative w-full mt-[20px]">
                  <input
                    type="number"
                    id="floating_outlined-2"
                    className="block w-[90%] px-4 h-[48px] text-sm text-gray-900 bg-transparent border-[1.5px] border-[#B8C0CD] rounded-[6px] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6687FF] peer"
                    placeholder=" "
                    onChange={(e) => {
                      setAmount(e.target.value);
                    }}
                    min={0}
                    step="any"
                    onPaste={preventPasteNegative}
                    onKeyDown={preventNegativeValues}
                    required
                  />
                  <label
                    htmlFor="floating_outlined-2"
                    className="bg-[#F6F8FA] absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Amount
                  </label>
                </div>

                <div className="relative w-full mt-[20px]">
                  <textarea
                    id="floating_outlined-1"
                    placeholder=" "
                    onChange={(e) => {
                      setApiKey(e.target.value);
                    }}
                    name="token"
                    className="block w-[90%] px-4 py-2 h-32 text-sm text-gray-900 bg-transparent border-[1.5px] border-[#B8C0CD] rounded-[6px] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6687FF] peer"
                  ></textarea>
                  <label
                    htmlFor="floating_outlined-1"
                    className="bg-[#F6F8FA] absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-8 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    API Key
                  </label>
                </div>

                <div className="relative w-full mt-[20px]">
                  <input
                    type="url"
                    id="floating_outlined-3"
                    className="block w-[90%] px-4 h-[48px] text-sm text-gray-900 bg-transparent border-[1.5px] border-[#B8C0CD] rounded-[6px] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#6687FF] peer"
                    placeholder=" "
                    onChange={(e) => {
                      setCallbackUrl(e.target.value);
                    }}
                    pattern="https?://.*"
                    required
                  />
                  <label
                    htmlFor="floating_outlined-3"
                    className="bg-[#F6F8FA] absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Callback URL
                  </label>
                </div>
                <div className="w-[92%]">
                  <button className="rounded-[6px] bg-[#6687FF] text-white text-[14px] py-[8px] px-[30px] mt-[20px] float-right">
                    Generate
                  </button>
                </div>
              </>
            ) : (
              <div className=" w-[92%] mt-3">
                <p className="bg-gray-200  py-1.5 px-4 rounded-[4px]">
                  PG key is not enabled
                </p>
              </div>
            )}
          </Form>
        </div>

        <div className="w-full p-[40px]">
          <p className="text-[14px] text-[#1B163B] font-[500] mb-[20px]">
            Generated Fields
          </p>
          <div className="flex justify-between items-center pt-[10px]">
            <p className="text-[#717171] text-[14px] mr-2 w-full max-w-fit">
              Payment URL:{" "}
            </p>
            <div className="flex items-center bg-[#EEF1F6] text-[#717171]  text-sm p-4 h-10 w-[75%] gap-x-4 shadow rounded-lg mx-auto">
              <a
                href={res}
                target="_blank"
                className="text-wrap w-full text-[#0356fc] text-[14px] truncate"
                rel="noreferrer"
              >
                {res}
              </a>
              {res && (
                <button
                  className="shrink-0 w-10 flex justify-center items-center p-1 z-10 rounded-lg"
                  onClick={() => {
                    copyToClipboard(res);
                  }}
                >
                  <MdContentCopy
                    className="cursor-pointer text-[#717171]  text-xl"
                    style={{
                      fontSize: "15px",
                      color: "",
                      backgroundColor: "transparent",
                    }}
                  />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneratePgLink;
