import React, { useEffect, useState } from "react";
import { addFromValues } from "../../CurrentCargeTab/Tabs/RateTab";
import { useMutation } from "@apollo/client";
import { CANCEL_REQUEST, GET_MDR_REQUESTS } from "../../../../../../Qurries";
import { toast } from "react-toastify";
import ConfirmationBox from "../../../../../../components/ConfermationBox";
import Modal from "../../../../../../components/Modal/Modal";
const _ = require("lodash");

const OverviewRate = ({ mode, status }: any) => {
  const filterdArray = addFromValues(
    _.sortBy(mode?.range_charge, (item: any) =>
      item.upto === null ? Infinity : item.upto,
    ),
  );

  const modes = ["UPI", "CORPORATE CARDS", "INTERNATIONAL PG"];

  return filterdArray.map((d: any, i: number) => {
    return (
      <div
        className={
          "grid gap-x-2 " +
          (status === "APPROVED" ? " grid-cols-6" : " grid-cols-5")
        }
        key={i}
      >
        <div>
          <p className=" text-[#1B163B] text-xs uppercase font-normal">
            {mode?.platform_type} <br />
            {!modes.includes(mode?.platform_type) && (
              <span className="text-[8px]">( {mode?.payment_mode} )</span>
            )}
          </p>
        </div>
        <div>
          <p className=" text-[#1B163B] text-xs font-normal">
            {d?.upto
              ? `${d?.from > 0 ? d?.from : "<="} ${
                  d?.upto >= d?.from && d?.from !== 0 ? "-" + d?.upto : d?.upto
                }`
              : `>=${d?.from}`}
          </p>
        </div>
        <div>
          <p className=" text-[#1B163B] text-xs font-normal">
            {d?.charge_type === "FLAT"
              ? "₹" + d?.base_charge
              : d?.base_charge + "%"}
          </p>
        </div>
        <div>
          <p className=" text-[#1B163B] text-xs font-normal">
            {d?.charge_type === "FLAT"
              ? "₹" + d?.commission?.toFixed(2)
              : d?.commission?.toFixed(2) + "%"}
          </p>
        </div>
        <div>
          <p className=" text-[#1B163B] text-xs font-normal">
            {d?.charge_type === "FLAT" ? "₹" + d?.charge : d?.charge + "%"}
          </p>
        </div>
        {status === "APPROVED" && (
          <div>
            <p className=" text-[#1B163B] text-xs font-normal">{d?.charge}</p>
          </div>
        )}
      </div>
    );
  });
};

function OverviewTab({ data, status, requestId, setOpen }: any) {
  const [cancelRequest] = useMutation(CANCEL_REQUEST, {
    refetchQueries: [
      {
        query: GET_MDR_REQUESTS,
      },
    ],
  });
  const [confermationBox, setConfermationBox] = useState(false);
  return (
    <div className="px-6 py-8 bg-[#EDF1F4] space-y-6 h-full max-h-screen overflow-y-auto">
      <div className="flex justify-between items-center">
        <Modal open={confermationBox} setOpen={setConfermationBox}>
          <ConfirmationBox
            setOpen={setConfermationBox}
            funtion={async () => {
              try {
                const res = await cancelRequest({
                  variables: {
                    req_id: requestId,
                  },
                });
                setOpen(false);
                toast.success("Your request has been cancelled");
              } catch (err) {
                toast.error(`${err}`);
              }
            }}
            confirmationText="save the changes"
            ButtonText="Save"
          />
        </Modal>
        <div
          className={
            "px-[18px] py-2  text-sm border max-w-fit rounded-[4px]  " +
            (status === "APPROVED"
              ? " bg-[#2296351A] text-[#229635] border-[#229635BF]"
              : status === "REJECTED" || status === "CANCELLED"
                ? " bg-[#F92D001A] text-[#F92D00] border-[#F92D00BF]"
                : "bg-[#EBA00F1A] text-[#EBA00F] border-[#EBA00FBF]")
          }
        >
          {status === "APPROVED"
            ? "Your Request has been Approved"
            : status === "REJECTED"
              ? " Your Request has been Rejected"
              : status === "CANCELLED"
                ? "You have cancelled your request"
                : "Your Request is Under Review"}
        </div>

        {status === "INITIATED" && (
          <button
            onClick={() => setConfermationBox(true)}
            className="bg-edviron_black text-xs text-white rounded-md px-4 py-2"
          >
            Cancel Request
          </button>
        )}
      </div>

      <div
        className={
          "grid  " + (status === "APPROVED" ? " grid-cols-6" : " grid-cols-5")
        }
      >
        <div>
          <p className=" text-[#777777] text-xs font-normal">Payment Mode</p>
        </div>
        <div>
          <p className=" text-[#777777] text-xs font-normal">Range</p>
        </div>
        <div>
          <p className=" text-[#777777] text-xs font-normal">Base Rates</p>
        </div>
        <div>
          <p className=" text-[#777777] text-xs font-normal">
            My Commission <br /> ( Requested )
          </p>
        </div>
        <div>
          <p className=" text-[#777777] text-xs font-normal">
            Requested Rate <br /> ( Total )
          </p>
        </div>
        {status === "APPROVED" && (
          <div>
            <p className=" text-[#777777] text-xs font-normal">
              Final Rate (Applied )
            </p>
          </div>
        )}
      </div>
      {data?.map((mode: any, i: any) => {
        return <OverviewRate key={i} status={status} mode={mode} />;
      })}
    </div>
  );
}

export default OverviewTab;
