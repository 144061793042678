import React, { useContext } from "react";
import { dashboardContext } from "../../../Dashboard";

function KycDetails() {
  const { user } = useContext(dashboardContext);

  return (
    <div className=" bg-white rounded-md px-4 py-6 m-4">
      <h3 className=" text-base font-medium text-black">KYC Details</h3>
      <div>
        <ul className=" space-y-1 my-2">
          <li className="text-gray-600 text-sm font-medium">
            GSTIN:{" "}
            <span className="ml-1">{user?.getUserQuery?.gstIn || "NA"}</span>
          </li>
          <li className="text-gray-600 text-sm font-medium">Bank Details:--</li>
          <li className="text-gray-600 text-sm font-medium">
            Account Holder Name:{" "}
            {user?.getUserQuery?.bank_details?.account_holder_name || "NA"}
          </li>
          <li className="text-gray-600 text-sm font-medium">
            Account Number:{" "}
            {user?.getUserQuery?.bank_details?.account_number || "NA"}
          </li>
          <li className="text-gray-600 text-sm font-medium">
            IFSC: {user?.getUserQuery?.bank_details?.ifsc_code || "NA"}
          </li>
        </ul>
        <p className="text-black text-sm font-medium">Notes:</p>
        <ul className=" space-y-1 my-2 list-decimal	ml-4">
          <li className="text-black text-sm font-medium">
            Your commission will be settled to bank details mentioned above,
            irrespective of what is mentioned in invoice.
          </li>
          <li className="text-black text-sm font-medium">
            In case your business is GST-registered, you need to update your
            GSTIN with us to raise tax-compliant invoices. <br /> Ensure these
            are correctly upated here before raising the invoice. To change your
            gst and bank details, please reach out to your account manager and
            <a
              className="text-purple-700 ml-1"
              href="mailto:contact@edviron.com"
            >
              contact@edviron.com
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default KycDetails;
