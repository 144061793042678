import React, { useState } from "react";
// import { addFromValues } from "../../CurrentCargeTab/Tabs/RateTab";
import { useMutation } from "@apollo/client";
import { cloneDeep } from "@apollo/client/utilities";
import { FiEdit } from "react-icons/fi";
import { toast } from "react-toastify";
import { CREATE_MDR_REQUEST } from "../../../../../../Qurries";
import SingleSchoolRangeModal from "../../CurrentCargeTab/SingleSchoolRangeModal";
const _ = require("lodash");
export const addFromValues = (arr: any) => {
  let previousUpto = 0;
  return arr.map((item: any) => {
    const newItem = { ...item, from: previousUpto };
    previousUpto = item.upto !== null ? item.upto : previousUpto;
    return newItem;
  });
};
const SchoolPaymentMode = ({ mode }: any) => {
  const [rangeCharge, setRangeCharge] = useState<any>(mode?.range_charge);

  const preventNegativeValues = (e: any) =>
    ["+", "-"].includes(e.key) && e.preventDefault();

  const filterdArray = addFromValues(
    _.sortBy(rangeCharge, (item: any) =>
      item.upto === null ? Infinity : item.upto
    )
  );

  return filterdArray.map((range: any, rangeIndex: number) => {
    return (
      <div className="grid gap-x-2 grid-cols-5" key={rangeIndex}>
        <div>
          <p className=" text-[#1B163B] text-xs uppercase font-normal">
            {mode?.platform_type} (
            <span className="text-[8px]">{mode?.payment_mode}</span>)
          </p>
        </div>
        <div>
          <p className=" text-[#1B163B] text-xs font-normal">
            {range?.upto
              ? `${range?.from > 0 ? range?.from : "<="} ${
                  range?.upto >= range?.from && range?.from !== 0
                    ? "-" + range?.upto
                    : range?.upto
                }`
              : `>=${range?.from}`}
          </p>
        </div>
        <div>
          <p className=" text-[#1B163B] text-xs font-normal">
            {range?.charge_type === "FLAT"
              ? "₹" + range?.base_charge
              : range?.base_charge + "%"}
          </p>
        </div>
        <div>
          <p className=" text-[#1B163B] text-xs font-normal">
            {range?.charge_type === "FLAT"
              ? "₹" + range?.commission?.toFixed(2)
              : range?.commission?.toFixed(2) + "%"}
          </p>
        </div>
        <div>
          <p className=" text-[#1B163B] text-xs font-normal">
            {range?.charge_type === "FLAT"
              ? "₹" + range?.charge
              : range?.charge + "%"}
          </p>
        </div>
      </div>
    );
  });
};
function PreviousRate({ data, setOpen }: any) {
  const [paymentModes, setPaymentModes] = useState(
    data.map((d: any) => {
      return {
        ...d,
        range_charge: d.range_charge.map((d: any) => {
          return {
            commission: 0,
            base_charge: d.charge,
            ...d,
          };
        }),
      };
    })
  );

  return (
    <>
      <div className="px-6 py-8 bg-[#EDF1F4] space-y-6 h-full max-h-screen overflow-y-auto">
        <>
          <div className="grid grid-cols-5">
            <div>
              <p className=" text-[#777777] text-xs font-normal">
                Payment Mode
              </p>
            </div>
            <div>
              <p className=" text-[#777777] text-xs font-normal">Range</p>
            </div>
            <div>
              <p className=" text-[#777777] text-xs font-normal">Base Rates</p>
            </div>
            <div>
              <p className=" text-[#777777] text-xs font-normal">
                My Commission
              </p>
            </div>
            <div>
              <p className=" text-[#777777] text-xs font-normal">Final Rates</p>
            </div>
          </div>
          {paymentModes?.map((mode: any, i: any) => {
            return <SchoolPaymentMode mode={mode} key={i} />;
          })}
        </>
      </div>
    </>
  );
}

export default PreviousRate;
