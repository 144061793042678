import React, { useContext, useEffect, useState } from "react";
import { LiaRupeeSignSolid } from "react-icons/lia";
import LineGraph from "../../../../../../components/Graph/LineGraph";

import ToolTip from "../../../../../../components/ToolTip";
import { MdContentCopy } from "react-icons/md";
import { handleCopyContent } from "../../../../components/school/AllSchoolList";
import { _Table } from "../../../../../../components/Table";
import { dashboardContext } from "../../../../Dashboard";
function CommissionTab({ data }: any) {
  const [total, setTotal] = useState(
    data
      ?.filter((s: any) => {
        const currentMonth = new Date().getMonth();
        return (
          new Date(s.createdAt)?.getMonth() === currentMonth &&
          s.status.toUpperCase() === "SUCCESS"
        );
      })
      .map((d: any) => d.commission)
      .reduce((a: any, b: any) => a + b, 0) || 0,
  );

  return (
    <>
      {data?.length > 0 ? (
        <div className="p-8 bg-[#EDF1F4]">
          <h2 className="text-[#1B163B] text-sm font-[600]">Reports</h2>
          <div className="w-full px-4 -ml-2 mb-4 mt-[14px] flex justify-between items-end">
            <div className="text-[#229635] font-[400] flex ">
              <span className="text-[32px] flex items-center">
                <LiaRupeeSignSolid />
                {total ? (
                  <span>
                    {(Math.floor(total * 100) / 100).toLocaleString("hi-in")}
                  </span>
                ) : (
                  <span>0</span>
                )}
              </span>
              <span className="text-sm text-[#717171] flex items-center ml-2">
                {`(this month)`}
              </span>
            </div>
          </div>
          <div className="px-4 mb-8">
            <LineGraph dataArray={data} commission={true} />
          </div>

          <_Table
            perPage={false}
            exportBtn={false}
            bgColor={" bg-transparent"}
            boxPadding={" p-0"}
            pagination={true}
            data={[
              [
                "Sr.No",
                "Date",
                "Order ID",
                "Order Amount",
                "Transaction Amount",
                "My Commission",
              ],
              ...data?.map((row: any, index: number) => [
                <div className="text-center pr-4">{index + 1}</div>,
                <div className=" max-w-[5rem]" key={row?.orderID}>
                  {new Date(row?.createdAt).toLocaleString("hi")}
                </div>,

                <div className="flex justify-between items-center">
                  <div
                    className="truncate max-w-[9.5rem]"
                    title={row?.collect_id}
                  >
                    {row?.collect_id}
                  </div>
                  <button
                    onClick={() => {
                      handleCopyContent(row?.collect_id);
                    }}
                  >
                    <ToolTip text="Copy Order ID">
                      <MdContentCopy
                        className="cursor-pointer text-[#717171] shrink-0 text-xl"
                        style={{
                          fontSize: "22px",
                          color: "",
                          backgroundColor: "transparent",
                        }}
                      />
                    </ToolTip>
                  </button>
                </div>,
                <div className="text-center pr-4">{`₹${row?.order_amount !== null ? (Math.floor(row?.order_amount * 100) / 100).toLocaleString("hi-in") : 0}`}</div>,
                <div className="text-center pr-4">{`₹${row?.transaction_amount !== null ? (Math.floor(row?.transaction_amount * 100) / 100).toLocaleString("hi-in") : 0}`}</div>,
                <div className="text-center pr-4">{`₹${row?.commission !== null ? (Math.floor(row?.commission * 100) / 100).toLocaleString("hi-in") : 0}`}</div>,
              ]),
            ]}
          />
        </div>
      ) : (
        <p className="p-4">No Data</p>
      )}
    </>
  );
}

export default CommissionTab;
