export const resultFilter = (firstArray: any, secondArray: any) => {
  return firstArray.filter(
    (firstArrayItem: any) =>
      !secondArray.some(
        (secondArrayItem: any) =>
          firstArrayItem.payment_mode === secondArrayItem.payment_mode &&
          firstArrayItem.platform_type === secondArrayItem.platform_type
      )
  );
};
